import React from "react";
// import Seo from "../components/seo";
import { Link } from "gatsby";

const ErrorPage = () => {
  return (
    <>

      {/* <Seo title="Page not found" /> */}

      <main>
        <h1>Page not found</h1>

        <p>
          Go back to the <Link to="/">Home page</Link>
        </p>
      </main>
    </>
  );
};

export default ErrorPage;
